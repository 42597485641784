import './Marketing.css'

function Marketing() {
    return (
        <div className={"wrapper"}>
            <h1>Marketing</h1>
        </div>
    )
}

export default Marketing;